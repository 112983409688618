import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useState } from "react";
import { mapImage } from "../images";

type ContactFormSubmitStatus =
  | "Unsubmitted"
  | "Submitting"
  | "SubmittedWithSuccess"
  | "SubmittedWithError";

interface MessageToTwinOak {
  name: string;
  email: string;
  phone: string;
  company: string;
  message: string;
}

const defaultMessageToTwinOak: MessageToTwinOak = {
  name: "",
  email: "",
  phone: "",
  company: "",
  message: "",
};

export const ContactUs: React.FC = () => {
  const pageContainerStyle: CSSProperties = {
    backgroundImage: `url(${mapImage})`,
  };
  const formContainerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,
  };

  const [
    formSubmitStatus,
    setFormSubmitStatus,
  ] = useState<ContactFormSubmitStatus>("Unsubmitted");

  const [messageToTwinOak, setMessageToTwinOak] = useState(
    defaultMessageToTwinOak
  );

  function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.stopPropagation();
    event.preventDefault();
    setFormSubmitStatus("Submitting");

    const init: RequestInit = {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(messageToTwinOak),
    };

    // const baseApiUrl = "https://localhost:5001";
    const baseApiUrl = "https://demo-twinoak-com.azurewebsites.net";

    fetch(baseApiUrl + "/contact-twin-oak", init)
      .then(function (response) {
        if (!response.ok) {
          throw new Error("Not 2xx response");
        }
        setFormSubmitStatus("SubmittedWithSuccess");
      })
      .catch(function () {
        setFormSubmitStatus("SubmittedWithError");
      });
  }

  function handleInputChange({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setMessageToTwinOak((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <div className="comp_base_container_inner" style={pageContainerStyle}>
      <div className="tos_form_headings">
        <h1 className="tos_h1_contact text_white">Contact</h1>
        <p className="tos_main_txt text_white">
          Feel free to connect with us regarding your modernization needs.
        </p>
      </div>
      {(formSubmitStatus === "Unsubmitted" ||
        formSubmitStatus === "Submitting") && (
        <form onSubmit={handleFormSubmit}>
          <div className="tos_qa_expander">
            <div className="tos_contact_desc">
              <p className="tos_contact_desc_txt">
                Prefer email?{" "}
                <a href="mailto:Info@TwinOakSolutions.com" target="_email">
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  Info@TwinOakSolutions.com
                </a>
              </p>
            </div>
            <div style={formContainerStyle}>
              <div className="tos_flex_form_column">
                <input
                  type="text"
                  className="tos-form_input"
                  placeholder="*Your Name"
                  required
                  name="name"
                  onChange={handleInputChange}
                  value={messageToTwinOak.name}
                />
                <input
                  type="text"
                  className="tos-form_input"
                  placeholder="*Your Email"
                  required
                  name="email"
                  onChange={handleInputChange}
                  value={messageToTwinOak.email}
                />
                <input
                  type="text"
                  className="tos-form_input"
                  placeholder="Your Phone"
                  name="phone"
                  onChange={handleInputChange}
                  value={messageToTwinOak.phone}
                />
                <input
                  type="text"
                  className="tos-form_input"
                  placeholder="Your Company"
                  name="company"
                  onChange={handleInputChange}
                  value={messageToTwinOak.company}
                />
              </div>
              <div className="tos_flex_form_column">
                <textarea
                  className="tos-form_Textarea tos-form_input"
                  placeholder="*Your Message"
                  required
                  name="message"
                  onChange={handleInputChange}
                  value={messageToTwinOak.message}
                ></textarea>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="tos_form_button"
            disabled={formSubmitStatus === "Submitting"}
          >
            <span className="tos_form_button_text">Send Message</span>
          </button>
        </form>
      )}

      {formSubmitStatus === "SubmittedWithError" && (
        <div className="tos_qa_expander">
          <div className="tos_contact_warning">
            <p className="tos_contact_desc_txt">
              An unexpected error occurred. Please email us at{" "}
              <a href="mailto:Info@TwinOakSolutions.com" target="_email">
                <FontAwesomeIcon icon={faEnvelope} /> Info@TwinOakSolutions.com
              </a>
            </p>
          </div>
        </div>
      )}
      {formSubmitStatus === "SubmittedWithSuccess" && (
        <div className="tos_qa_expander">
          <div className="tos_contact_success">
            <p className="tos_contact_desc_txt">
              Thanks for contacting us. We'll get back to you as soon as we can.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
