import React from "react";
import { ContactUs } from "../components/ContactUs";
import { PageLayout } from "../components/PageLayout";
import { contactUsPageHeroImage } from "../images";

const ContactPage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${contactUsPageHeroImage})`}
      heroOverlayLeadElement="Reach Out to Us"
      heroOverlaySecondaryElement="We'd love to discuss working together"
      baseContainerClassname="contact_set"
    >
      <ContactUs />
    </PageLayout>
  );
};

export default ContactPage;
